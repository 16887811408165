export default class MarginBlockTune {

     static get isTune() {
          return true;
     }

     static get DEFAULT_MARGINS() {
          return {
               marginBottom: '0px',
               marginTop: '0px'
          }
     }

     getMargins() {
          // Extract margin values from settings or use default values
          let margins = MarginBlockTune.DEFAULT_MARGINS;
          if (this.settings && this.settings.default) {
               margins = this.settings.default;
          }
          return margins;
     }

     constructor({ api, block, data }) {
          this.api = api;
          this.block = block;
          this.wrapper = null;

          // Ensure defaultMargins is defined before using it
          const defaultMargins = this.getMargins();

          this.settings = {
               default: defaultMargins
          };

          this.data = {
               marginTop: data ? data.marginTop : defaultMargins.marginTop,
               marginBottom: data ? data.marginBottom : defaultMargins.marginBottom
          };

          //console.log('data', data);
     }

     wrap(blockContent) {
          // Find the parent element with the class 'ce-block--focused'
          let focusedBlock = blockContent.closest('.ce-block--focused');

          if (focusedBlock) {
               // Apply the margin styles to the focused block
               focusedBlock.style.marginTop = this.data.marginTop;
               focusedBlock.style.marginBottom = this.data.marginBottom;
          } else {
               // Directly apply the margin styles to blockContent
               blockContent.style.marginTop = this.data.marginTop;
               blockContent.style.marginBottom = this.data.marginBottom;
          }

          return blockContent; // Return the original blockContent
     }

     render() {
          this.wrapper = document.createElement('div');
          this.wrapper.className = 'margin-tune-wrapper mt-2';
          this.wrapper.style.display = 'grid';
          this.wrapper.style.gridTemplateColumns = '1fr 1fr';
          this.wrapper.style.gridColumnGap = '5px';

          // Create Margin Top Input
          this.marginTopInput = document.createElement('input');
          this.marginTopInput.type = 'number';
          this.marginTopInput.placeholder = 'Margin Top';
          this.marginTopInput.className = 'form-control margin-top-input';
          this.marginTopInput.style.height = '0';

          // Create Margin Bottom Input
          this.marginBottomInput = document.createElement('input');
          this.marginBottomInput.type = 'number';
          this.marginBottomInput.placeholder = 'Margin Bottom';
          this.marginBottomInput.className = 'form-control margin-bottom-input';
          this.marginBottomInput.style.height = '0';

          // Parse the numeric value from the margins, assuming they are strings like "50px"
          const marginTopValue = parseInt(this.data.marginTop, 10);
          const marginBottomValue = parseInt(this.data.marginBottom, 10);

          // Set the parsed values as the input values
          this.marginTopInput.value = isNaN(marginTopValue) ? '' : marginTopValue;
          this.marginBottomInput.value = isNaN(marginBottomValue) ? '' : marginBottomValue;

          // Attach the input event listeners
          this.marginTopInput.addEventListener('input', () => {
               const value = this.marginTopInput.value;
               this.data.marginTop = value ? `${value}px` : '0px';
               this.updateBlockStyle();
          });
          this.marginBottomInput.addEventListener('input', () => {
               const value = this.marginBottomInput.value;
               this.data.marginBottom = value ? `${value}px` : '0px';
               this.updateBlockStyle();
          });

          this.wrapper.appendChild(this.marginTopInput);
          this.wrapper.appendChild(this.marginBottomInput);

          console.log('Render data:', this.data);  // Debug log
          return this.wrapper;
     }

     updateBlockStyle() {
          // Assuming 'this.block' contains the actual block data with the 'id' property
          let blockId = this.block.id; // Retrieve the id from the block data
          let blockElement = document.querySelector(`.ce-block[data-id="${blockId}"] > div`);

          if (blockElement) {
               blockElement.style.marginTop = this.data.marginTop;
               blockElement.style.marginBottom = this.data.marginBottom;
          }
     }


     save() {
          return (
               this.data
          );
     }

}