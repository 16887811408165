import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const Auth = ({ children }) => {
     const [isLoggedIn, setIsLoggedIn] = useState(false);
     const navigate = useNavigate();
     const auth = getAuth();

     useEffect(() => {
          const unsubscribe = onAuthStateChanged(auth, (user) => {
               if (user) {
                    setIsLoggedIn(true);
               } else {
                    navigate('/backoffice/login');
               }
          });

          return () => unsubscribe(); // Cleanup subscription on unmount
     }, [auth, navigate]);

     return isLoggedIn ? children : null;
};

export default Auth;