import React, { useEffect, useRef, useState } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import NestedList from '@editorjs/nested-list';
import Alert from 'editorjs-alert';
import Paragraph from '@editorjs/paragraph';
import TwoNewColumns from '../../tools/TwoNewColumns';
import TwoColumns from '../../tools/Twocolumns';
import ThreeColumns from '../../tools/Threecolumns';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';
import ImageTool from '@editorjs/image';
import MarginBlockTune from '../../tools/MarginTune';
import ColorPlugin from 'editorjs-text-color-plugin';
import Checklist from '@editorjs/checklist';
import Hyperlink from 'editorjs-hyperlink';
import Strikethrough from '@sotaproject/strikethrough';
import DragDrop from 'editorjs-drag-drop';

// Importing CSS for Editor
import './Editor.css';

// Firebase Database and Storage imports
import { storage, db } from "../../../../../firebase";
import { ref as storageRef, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useParams } from 'react-router-dom';
import ColumnTune from '../../tools/ColumnTune';

function Create() {
     const { postId } = useParams(); // Retrieve postId from URL
     const [title, setTitle] = useState(""); // Empty state for page Title currently editing 
     const editorRef = useRef(null); // Ref for EditorJS instance
     const [editorContent, setEditorContent] = useState(null); // State for Editor content
     const [uploadedImages, setUploadedImages] = useState([]); // State for tracking uploaded images

     // Function to initialize the editor
     const initializeEditor = (content = null) => {
          // Destroy existing instance if it exists
          if (editorRef.current && typeof editorRef.current.destroy === 'function') {
               editorRef.current.destroy();
          }

          // Initializing new instance of EditorJS
          if (!editorRef.current) {
               const main_tools = {
                    columns: {
                         class: TwoColumns,
                         tunes: ['anyTune', 'columnTune'],
                    },
                    threeColumns: {
                         class: ThreeColumns,
                         tunes: ['anyTune', 'columnTune'],
                    },
                    header: {
                         class: Header,
                         inlineToolbar: ['bold', 'italic', 'hyperlink', 'strikethrough', 'Color'],
                         tunes: ['anyTuneName', 'anyTune', 'columnTune'],
                         autofocus: false,
                         config: {
                              placeholder: 'Heading'
                         },
                    },
                    paragraph: {
                         class: Paragraph,
                         inlineToolbar: ['bold', 'italic', 'hyperlink', 'strikethrough', 'Color'],
                         tunes: ['anyTuneName', 'anyTune', 'columnTune'],
                         autofocus: false,
                    },
                    strikethrough: Strikethrough,
                    image: {
                         class: ImageTool,
                         config: {
                              uploader: {
                                   uploadByFile(file) {
                                        return new Promise((resolve, reject) => {
                                             const fileRef = storageRef(storage, `images/${file.name}`);
                                             const uploadTask = uploadBytesResumable(fileRef, file);

                                             uploadTask.on('state_changed',
                                                  (snapshot) => {
                                                       // handle progress
                                                  },
                                                  (error) => {
                                                       reject('Upload failed: ', error);
                                                  },
                                                  () => {
                                                       getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                                            setUploadedImages(prevImages => [...prevImages, downloadURL]);
                                                            resolve({
                                                                 success: 1,
                                                                 file: { url: downloadURL }
                                                            });
                                                       });

                                                  }
                                             );
                                        });
                                   }
                              }
                         }
                    },
                    list: {
                         class: NestedList,
                         inlineToolbar: ['bold', 'italic', 'hyperlink', 'strikethrough', 'Color'],
                         tunes: ['anyTune', 'columnTune'],
                         autofocus: false,
                         config: {
                              placeholder: 'List',
                              defaultStyle: 'unordered'
                         },
                    },
                    alert: {
                         class: Alert,
                         inlineToolbar: ['bold', 'italic', 'hyperlink', 'strikethrough', 'Color'],
                         tunes: ['anyTune', 'columnTune'],
                         autofocus: false,
                         config: {
                              placeholder: 'Alert',
                         },
                    },
                    checklist: {
                         class: Checklist,
                         inlineToolbar: ['bold', 'italic', 'hyperlink', 'strikethrough', 'Color'],
                    },
                    Color: {
                         class: ColorPlugin,
                         config: {
                              colorCollections: ["#FFFFFF", "#C0C0C0", "#808080", "#000000", "#FF0000", "#800000", "#FFFF00", "#808000", "#00FF00", "#008000", "#00FFFF", "#008080", "#0000FF", "#000080", "#FF00FF", "#800080"],
                              defaultColor: '#333333',
                              type: 'text',
                              customPicker: true // add a button to allow selecting any colour  
                         }
                    },
                    hyperlink: {
                         class: Hyperlink,
                         config: {
                              shortcut: 'CMD+L',
                              target: '_blank',
                              rel: 'nofollow',
                              availableTargets: ['_blank', '_self'],
                              availableRels: ['author', 'noreferrer'],
                              validate: false,
                         }
                    },
                    anyTuneName: {
                         class: AlignmentTuneTool,
                         config: {
                              default: "left",
                         },
                    },
                    anyTune: {
                         class: MarginBlockTune,
                         config: {
                              default: {
                                   marginBottom: "0px",
                                   marginTop: "0px"
                              }
                         }
                    },
                    columnTune: {
                         class: ColumnTune,
                         config: {
                              default: {
                                   activeClass: "",
                              }
                         }
                    }
               };
               editorRef.current = new EditorJS({
                    holder: 'editorjs',
                    autofocus: false,
                    placeholder: 'Let`s write some text..',
                    minHeight: 1,
                    tools: main_tools,
                    tunes: ['anyTune'],
                    data: content ? { blocks: content } : {},
                    onReady: () => {
                         // Select the main editor element
                         const editorElement = document.querySelector('.codex-editor__redactor');

                         // Check if the element exists
                         if (editorElement) {
                              // Add your custom class
                              editorElement.classList.add('row');
                         }

                         // Initialize the DragDrop module with the editor instance
                         new DragDrop(editorRef.current);
                    },
                    config: {
                         validate: (outputData) => validateEditorContent(outputData),
                    },
               });
          }
     };

     // Fetching content for existing pages
     useEffect(() => {
          console.log("Current postId:", postId);
          const fetchPageData = async () => {
               const docRef = doc(db, "posts", postId);
               const docSnap = await getDoc(docRef);

               if (docSnap.exists()) {
                    const pageData = docSnap.data();
                    console.log("Fetched data:", pageData.content);

                    if (pageData.content) {
                         // Process and use the content
                         const transformedContent = pageData.content.map(block => {
                              if (block.type === "image" && block.data && block.data.file && block.data.file.url) {
                                   block.data.file.url = block.data.file.url.replace(/&amp;/g, "&");
                              }
                              return block;
                         });

                         setTitle(pageData.title);
                         initializeEditor(transformedContent);
                    } else {
                         console.log("Document exists but no content found. Initializing empty editor.");
                         initializeEditor();
                    }
               } else {
                    console.log("Document does not exist. Initializing new editor.");
                    initializeEditor();
               }
          };

          if (postId) {
               fetchPageData();
          } else {
               initializeEditor();
          }
     }, [postId]);

     // Extracts URLs from editorData
     const extractImageUrls = (editorData) => {
          const urls = [];
          editorData.blocks.forEach(block => {
               if (block.type === 'image') {
                    urls.push(block.data.file.url);
               }
          });
          return urls;
     };

     // Validation logic for editor content
     const validateEditorContent = (outputData) => {
          console.log('Validating Editor Content:', outputData);
          setEditorContent(outputData);
          return true;
     };

     // Replaces image URLs in content blocks
     const replaceImageUrlsInBlocks = (blocks) => {
          return blocks.map(block => {
               if (block.type === 'image' && block.data && block.data.file && block.data.file.url) {
                    block.data.file.url = block.data.file.url.replace(/&amp;/g, "&");
               } else if (block.type === 'columns' && block.data && block.data.cols) {
                    block.data.cols = block.data.cols.map(col => {
                         return {
                              ...col,
                              content: replaceImageUrlsInBlocks(col.content)
                         };
                    });
               }
               return block;
          });
     };

     // Save function for editor content
     const handleSave = async () => {
          try {
               const outputData = await editorRef.current.save();
               console.log('Editor content:', outputData);
               // Replace URLs in image blocks
               outputData.blocks = replaceImageUrlsInBlocks(outputData.blocks);

               console.log('Editor content after processing:', outputData);

               const currentImages = extractImageUrls(outputData);
               const deletedImages = uploadedImages.filter(url => !currentImages.includes(url));

               // Delete removed images from Firebase
               deletedImages.forEach(async (url) => {
                    const fileRef = storageRef(storage, url);
                    try {
                         await deleteObject(fileRef);
                         console.log(`Deleted image from Firebase: ${url}`);
                    } catch (error) {
                         console.error(`Error deleting image: ${url}`, error);
                    }
               });

               setUploadedImages(currentImages);

               // Validate the data or perform any additional processing here
               const isValid = validateEditorContent(outputData);

               if (isValid) {
                    // Firestore document reference
                    const docRef = doc(db, "posts", postId);
                    // Update or set the document in Firestore
                    await setDoc(docRef, { content: outputData.blocks }, { merge: true });
                    console.log("Document successfully written!");

               } else {
                    console.log('Invalid data. Save operation cancelled.');
               }
          } catch (error) {
               console.error('Error saving editor content:', error);
          }
     };

     return (
          <>
               <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <nav aria-label="breadcrumb">
                         <ol className="breadcrumb">
                              <li className="breadcrumb-item"><a href="/backoffice">Dashboard</a></li>
                              <li className="breadcrumb-item"><a href="/backoffice/posts">Posts</a></li>
                              <li className="breadcrumb-item active" aria-current="page">{title}</li>
                         </ol>
                    </nav>
                    <div className="btn-toolbar mb-2 mb-md-0">
                         <button className='btn btn-primary pull-right' onClick={handleSave}>Save</button>
                    </div>
               </div>

               <div className="d-grid flex-wrap pt-3 pb-2 mb-3 border-bottom">
                    <div className='container-fluid'>
                         <div id="editorjs"></div>
                    </div>
               </div>
          </>
     );
}

export default Create;