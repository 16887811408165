import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../firebase';
import { collection, getDocs, doc, getDoc, query, where } from "firebase/firestore";
import "./Postlist.css";

function Postslist() {
     const [posts, setPosts] = useState([]);
     const [searchQuery, setSearchQuery] = useState(''); // New state for search query
     const navigate = useNavigate();
     const [isLoading, setIsLoading] = useState(true); // State to track loading status
     // New state for aggregated tags and their counts
     const [tagCounts, setTagCounts] = useState({});
     // State for active tags for filtering
     const [activeTags, setActiveTags] = useState([]);

     const handleSearchChange = (event) => {
          setSearchQuery(event.target.value.toLowerCase());
     };

     // Function to handle tag click
     const handleTagClick = (clickedTag) => {
          setActiveTags((prevActiveTags) => {
               // If the tag is already active, remove it from the array, otherwise add it
               if (prevActiveTags.includes(clickedTag)) {
                    return prevActiveTags.filter(tag => tag !== clickedTag);
               } else {
                    return [...prevActiveTags, clickedTag];
               }
          });
     };

     const handleReadMore = async (postId) => {
          // Fetch the post document by ID
          const docRef = doc(db, "posts", postId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
               const postData = docSnap.data();

               // Check if an external URL exists
               if (postData.externalUrl) {
                    // Ensure the URL starts with http:// or https://
                    let externalUrl = postData.externalUrl;
                    if (!externalUrl.startsWith('http://') && !externalUrl.startsWith('https://')) {
                         externalUrl = 'http://' + externalUrl;
                    }

                    // Open the external URL in a new tab
                    window.open(externalUrl, '_blank', 'noopener,noreferrer');
               } else if (postData.url) {
                    // Navigate using the internal URL field
                    navigate(`/blogs/${postData.url}`);
               } else {
                    console.log("URL not provided!");
               }
          } else {
               console.log("No such document!");
          }
     };

     useEffect(() => {
          const fetchPosts = async () => {
               setIsLoading(true);
               const querySnapshot = await getDocs(query(collection(db, "posts"), where("isPublished", "==", true)));
               const fetchedPosts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
               setPosts(fetchedPosts);

               // Aggregate tags and count their occurrences
               const counts = {};
               fetchedPosts.forEach(post => {
                    (post.tags || []).forEach(tag => {
                         counts[tag] = (counts[tag] || 0) + 1;
                    });
               });
               setTagCounts(counts);

               setIsLoading(false);
          };

          fetchPosts();
     }, []); // Ensure this is run once on component mount

     // Render tags with counts
     const renderTags = () => {
          return Object.entries(tagCounts).map(([tag, count]) => (
               <a key={tag}
                    className={`tag ${activeTags.includes(tag) ? 'active' : ''}`}
                    onClick={() => handleTagClick(tag)}>
                    {tag} <span style={{ float: 'right' }}>({count})</span>
               </a>
          ));
     };

     // Update the filteredPosts logic to include active tag filtering
     const filteredPosts = posts.filter(post => {
          const postTags = post.tags || [];
          // Filter posts based on search query and active tags
          const matchesSearchQuery = post.title.toLowerCase().includes(searchQuery) ||
               post.author.toLowerCase().includes(searchQuery) ||
               post.publishedOn.toLowerCase().includes(searchQuery);
          const matchesActiveTags = activeTags.length === 0 || activeTags.some(tag => postTags.includes(tag));
          return matchesSearchQuery && matchesActiveTags;
     });

     if (isLoading) {
          return (
               <div className='wrapper'>
                    <section className="module-cover text-center" style={{ background: '#0a1433' }}>
                         <div className="container">
                              <div className="row">
                                   <div className="col-md-12">
                                        <h1 className="m-b-20" style={{ color: '#fafafa' }}>
                                             <strong>Our Blogs</strong>
                                        </h1>
                                   </div>
                              </div>
                         </div>
                    </section>

                    <section className="module">
                         <div className="container px-4 py-5">
                              <div className="row g-5 pt-5">
                                   <div className="col-lg-4">
                                        <div className="sidebar">
                                             <aside className="widget widget-search">
                                                  <form>
                                                       <input
                                                            className="form-control"
                                                            type="search"
                                                            placeholder="Type Search Words"
                                                       />
                                                       <button className="search-button" type="submit">
                                                            <i className="bi bi-search"></i>
                                                       </button>
                                                  </form>
                                             </aside>

                                             <aside className="widget widget-tag-cloud">
                                                  <div className="widget-title">
                                                       <h6>Tags</h6>
                                                  </div>
                                                  <div className="tag-cloud">
                                                       <span>No tags added</span>
                                                  </div>
                                             </aside>
                                        </div>
                                   </div>
                                   <div className="col-lg-8">
                                        <div className='text-center'>
                                             <span className="mr-1">Loading</span>
                                             <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </div>

                                   </div>
                              </div>
                         </div>
                    </section>
               </div>
          );
     }

     return (
          <div className='wrapper'>
               <section className="module-cover text-center" style={{ background: '#0a1433' }}>
                    <div className="container">
                         <div className="row">
                              <div className="col-md-12">
                                   <h1 className="m-b-20" style={{ color: '#fafafa' }}>
                                        <strong>Our Blogs</strong>
                                   </h1>
                              </div>
                         </div>
                    </div>
               </section>

               <section className="module">
                    <div className="container px-4 py-5">
                         <div className="row g-5 pt-5">
                              <div className="col-lg-4">
                                   <div className="sidebar">
                                        <aside className="widget widget-search">
                                             <form>
                                                  <input
                                                       className="form-control"
                                                       type="search"
                                                       placeholder="Type Search Words"
                                                       value={searchQuery}
                                                       onChange={handleSearchChange}
                                                  />
                                                  <button className="search-button" type="submit">
                                                       <i className="bi bi-search"></i>
                                                  </button>
                                             </form>
                                        </aside>

                                        <aside className="widget widget-tag-cloud">
                                             <div className="widget-title">
                                                  <h6>Tags</h6>
                                             </div>
                                             <div className="tag-cloud">
                                                  {Object.keys(tagCounts).length > 0 ? renderTags() : "No tags added"}
                                             </div>
                                        </aside>
                                   </div>
                              </div>
                              <div className="col-lg-8">
                                   {filteredPosts.length > 0 ? (
                                        filteredPosts.map(post => (
                                             <article className="post" key={post.id} id={post.id}>
                                                  <div className="post-preview">
                                                       {post.thumbnail && post.thumbnail.length > 0 && (
                                                            <img src={post.thumbnail} />
                                                       )}
                                                  </div>
                                                  <div className="post-wrapper">
                                                       <div className="post-header">
                                                            <h2 className="post-title">{post.title}</h2>
                                                            <ul className="post-meta">
                                                                 <li>Published: {post.publishedOn}</li>
                                                                 <li>By: {post.author}</li>
                                                            </ul>
                                                       </div>
                                                       <div className="post-content">
                                                            <p>{post.description}</p>
                                                       </div>
                                                       <div className="post-more">
                                                            <button className='btn btn-primary' onClick={() => handleReadMore(post.id)}>Read more</button>
                                                       </div>
                                                  </div>
                                             </article>
                                        ))
                                   ) : (
                                        <div className="no-posts-message">
                                             No posts to be found by "{searchQuery}"
                                        </div>
                                   )}
                              </div>
                         </div>
                    </div>
               </section>
          </div>
     )
}

export default Postslist