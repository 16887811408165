import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Toast } from "react-bootstrap";
import "../css/Contact.css";

function Contact() {
  const recaptcha = useRef();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    comment: "",
  });

  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [errorShowToast, setErrorShowToast] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleToastClose = () => {
    setShowToast(false);
    setErrorShowToast(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();

    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
      setLoading(false);
    } else {
      // make form submission
      setLoading(true);

      fetch("https://submit-z7h6aweu6q-uc.a.run.app/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.text())
        .then((data) => {
          console.log(data);
          setLoading(false); // Set loading back to false when the request is complete

          // Reset the form data
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            comment: '',
          });

          // Calling toast method by passing string
          setShowToast(true);
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false); // Set loading back to false in case of an error
          // Calling toast method by passing string
          setErrorShowToast(true);
        });
    }
  };

  return (
    <div className="offcanvas offcanvas-end contact" data-bs-scroll="true" id="contact-us" aria-labelledby="contact-usLabel">
      <div className="offcanvas-header justify-content-center py-5">
        <h2 className="offcanvas-title" id="contact-usLabel"><strong>READY TO <br />GET STARTED?</strong></h2>

        <button type="button" className="close" data-bs-dismiss="offcanvas" aria-label="Close"><i className="bi bi-x-lg"></i></button>
      </div>
      <div className="offcanvas-body px-5">
        <p className="mb-4">Give us a call at <a href="tel:(+27) 83 262 9400">(+27) 83 262 9400</a> or fill out the form below and we’ll get back to you within two business days.</p>

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6 form-group">
              <input
                type="text"
                className="form-control"
                placeholder="First name"
                aria-label="First name"
                name="firstName"
                required
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Last name"
                aria-label="Last name"
                name="lastName"
                required
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email Address"
                aria-label="Email Address"
                name="email"
                required
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 form-group">
              <input
                type="phone"
                className="form-control"
                placeholder="Phone"
                aria-label="Phone"
                name="phone"
                required
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 form-group">
              <textarea
                type="text"
                className="form-control"
                placeholder="How can we help"
                aria-label="How can we help"
                name="comment"
                rows="4"
                required
                value={formData.comment}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row pt-2 pb-5">
            <div className="col-md-12 d-flex justify-content-center">
              <ReCAPTCHA ref={recaptcha} sitekey="6LdfQiApAAAAAAhn7UC_o-dpxq4i0HBANufX-DIz" theme="dark" />
            </div>
          </div>
          <button type="submit" className="btn btn-brand btn-circle" disabled={loading}>
            {loading ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Sending...
              </>
            ) : (
              'Submit Form'
            )}
          </button>

          <Toast show={errorShowToast} onClose={handleToastClose} className="position-fixed top-0 end-0 m-3 bg-danger" autohide="true" delay="5000">
            <Toast.Header>
              <strong className="me-auto">Error Submitting</strong>
            </Toast.Header>
            <Toast.Body>There was a issue submitting your form.</Toast.Body>
          </Toast>

          <Toast show={showToast} onClose={handleToastClose} className="position-fixed top-0 end-0 m-3 bg-success" autohide="true" delay="5000">
            <Toast.Header>
              <strong className="me-auto">Form Submitted!</strong>
            </Toast.Header>
            <Toast.Body>Your form has been successfully submitted.</Toast.Body>
          </Toast>
        </form>
      </div>
    </div>
  );
}

export default Contact;
