import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import { db, storage } from '../../../../../firebase';
import { collection, addDoc, onSnapshot, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import './Posts_table.css';

function Posts() {
     // State to store form data for creating/editing posts
     const [formData, setFormData] = useState({
          title: '',
          description: '',
          url: '',
          externalUrl: '',
          author: '',
          isPublished: false,
          thumbnail: '',
          publishedOn: '', // Added publishedOn to formData
          tags: []
     });

     // Function to reset form data to initial state
     const resetFormData = () => {
          setFormData({
               title: '',
               description: '',
               url: '',
               externalUrl: '',
               author: '',
               isPublished: false,
               thumbnail: '',
               publishedOn: '',
               tags: []
          });
     };

     // Example tags (can be fetched from a database or defined statically)
     const tagOptions = [
          { value: 'BPM', label: 'BPM' }
     ];

     // State for posts data fetched from Firestore
     const [posts, setPosts] = useState([]);

     // State to keep track of which post is being edited
     const [editId, setEditId] = useState(null);

     // State to keep track of the post dateTime
     const [dateTime, setDateTime] = useState("");

     // State to keep track of using external links
     const [useExternalLink, setUseExternalLink] = useState(false);

     // State to control the visibility of the modal
     const [isModalOpen, setIsModalOpen] = useState(false);

     // Hook to programmatically navigate between routes
     const navigate = useNavigate();

     // useEffect to fetch posts data from Firestore on component mount
     useEffect(() => {
          const unsubscribe = onSnapshot(collection(db, 'posts'), (snapshot) => {
               setPosts(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
          });
          return () => unsubscribe(); // Cleanup subscription on unmount
     }, []);

     // Function to handle form input changes and update state
     const handleChange = (e) => {
          const { name, value, type, checked } = e.target;
          // Convert URL to lowercase
          const newValue = name === 'url' ? value.toLowerCase() : (type === 'checkbox' ? checked : value);
          setFormData(prevFormData => ({
               ...prevFormData,
               [name]: newValue
          }));
     };

     // Function to handle thumbnail file changes
     const handleFileChange = async (e) => {
          const file = e.target.files[0];
          if (file) {
               const fileRef = storageRef(storage, `thumbnails/${file.name}`);
               try {
                    const uploadResult = await uploadBytes(fileRef, file);
                    const downloadURL = await getDownloadURL(uploadResult.ref);
                    setFormData(prevFormData => ({ ...prevFormData, thumbnail: downloadURL }));
               } catch (error) {
                    console.error('Error uploading file:', error);
               }
          }
     };

     // Function to Update state when datetime changes
     const handleChangeDateTime = (e) => {
          setDateTime(e.target.value);
     };

     // Function to handle checkbox change
     const handleExternalLinkCheckboxChange = (e) => {
          setUseExternalLink(e.target.checked);

          // Clear the value of the input field that's being hidden
          if (e.target.checked) {
               // If using external link, clear the internal URL field
               setFormData({ ...formData, url: '' });
          } else {
               // If not using external link, clear the external URL field
               setFormData({ ...formData, externalUrl: '' });
          }
     };

     // Function to handle changes in the CreatableSelect component for tags
     const handleTagChange = (newValue, actionMeta) => {
          console.group('Value Changed');
          console.log(newValue);
          console.log(`action: ${actionMeta.action}`);
          console.groupEnd();

          // Update the formData state with the full newValue array,
          // which is an array of objects with { value, label }
          setFormData({ ...formData, tags: newValue || [] });
     };


     // Function to handle form submission for creating new post
     const handleSubmit = async (e) => {
          e.preventDefault();
          // Extract just the string values for tags before saving to Firestore
          const tagsForFirestore = formData.tags.map(tag => (typeof tag === 'object' && tag.value) ? tag.value : tag);

          try {
               const newPost = {
                    ...formData,
                    tags: tagsForFirestore, // Save tags as an array of strings
                    // Use external link if checkbox is checked
                    url: useExternalLink ? formData.externalUrl : formData.url,
                    // Other fields...
               };
               await addDoc(collection(db, 'posts'), newPost);
               resetFormData(); // Reset form data after submission
          } catch (error) {
               console.error('Error creating post:', error);
          }
     };

     // Function to set form data for editing existing post
     // Function to set form data for editing existing post
     const handleEdit = (post) => {
          setEditId(post.id);

          // Check if the post has an external URL and set useExternalLink accordingly
          const hasExternalUrl = !!post.externalUrl;
          setUseExternalLink(hasExternalUrl);

          // Ensure tags are in the correct format for CreatableSelect
          const formattedTags = post.tags ? post.tags.map(tag => {
               return { value: tag, label: tag }; // Convert each string tag into an object
          }) : [];

          setFormData({
               ...post,
               tags: formattedTags, // Set the tags for CreatableSelect
               // If the post has an external URL, use it; otherwise, use the internal URL
               url: hasExternalUrl ? '' : post.url,
               externalUrl: hasExternalUrl ? post.externalUrl : '',
          });
     };


     // Function to handle updating an existing post
     const handleUpdate = async () => {
          try {
               // Extract just the string values for tags before saving to Firestore
               const tagsForFirestore = formData.tags.map(tag => tag.value || tag);

               // Ensure tags are only an array of strings before sending to Firestore
               const updatedData = {
                    ...formData,
                    tags: tagsForFirestore, // Save tags as an array of strings
                    // ... other fields
               };

               // Remove any keys that are not valid for Firestore document fields
               for (const key of Object.keys(updatedData)) {
                    if (key.startsWith('__') && key.endsWith('__')) {
                         delete updatedData[key];
                    }
               }

               const postRef = doc(db, 'posts', editId);
               await updateDoc(postRef, updatedData);
               setEditId(null);
               resetFormData(); // Reset form data after update
          } catch (error) {
               console.error('Error updating post:', error);
          }
     };


     // Function to handle viewing a post
     const handleView = async (postId) => {
          navigate(`/backoffice/create/${postId}`);
     };

     // Function to handle deleting a post
     const handleRemove = async (postId) => {
          const isConfirmed = window.confirm("Are you sure you want to delete this post?");
          if (isConfirmed) {
               try {
                    const postRef = doc(db, 'posts', postId);
                    await deleteDoc(postRef);
               } catch (error) {
                    console.error('Error removing post:', error);
               }
          }
     };

     return (
          <div>
               <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h2>Posts</h2>
                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createPostModal" onClick={resetFormData}>
                         Create
                    </button>
               </div>

               <div className="modal fade" id="createPostModal" tabIndex="-1" aria-labelledby="createPostModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                         <div className="modal-content">
                              <div className="modal-header">
                                   <h5 className="modal-title">Create New Post</h5>
                                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                                   <form className='row g-3' onSubmit={handleSubmit}>
                                        <div className='col-12'>
                                             <label htmlFor="thumbnail" className="form-label">Page Thumbnail</label><br />

                                             {/* Image Preview or Placeholder */}
                                             <div className='d-flex py-5 align-items-center justify-content-center border' style={{ height: '250px', position: 'relative', overflow: 'hidden' }}>
                                                  {formData.thumbnail ? (
                                                       <img src={formData.thumbnail} alt="Uploaded Thumbnail" style={{ width: '100%', height: '100%', position: 'absolute', objectFit: 'contain' }} />
                                                  ) : (
                                                       <span style={{ fontSize: '50px' }}><i className="bi bi-image"></i></span>
                                                  )}
                                             </div>

                                             <input type="file" name='thumbnail' id="thumbnail" onChange={handleFileChange} className='d-none' />

                                             {/* Button to re-upload image */}
                                             <button
                                                  onClick={(event) => {
                                                       event.preventDefault(); // Prevent form submission
                                                       document.getElementById('thumbnail').click();
                                                  }}
                                                  className="btn btn-sm btn-outline-success d-block mt-2 mx-auto">
                                                  Choose file
                                             </button>
                                        </div>
                                        <div className='col-12'>
                                             <label htmlFor="title" className="form-label">Page Title</label><br />
                                             <input type="text" name="title" placeholder="Title" className="form-control" id="title" value={formData.title} onChange={handleChange} />
                                        </div>
                                        <div className='col-12'>
                                             <label htmlFor="description" className="form-label">Description</label><br />
                                             <textarea type="text" name="description" placeholder="Description" className="form-control" id="description" value={formData.description} onChange={handleChange} />
                                        </div>
                                        {/* <div className='col-12'>
                                             <label htmlFor="seoTitle" className="form-label">SEO Title</label><br />
                                             <input type="text" name="seoTitle"  className="form-control" id="seoTitle" placeholder="SEO Title" value={formData.seoTitle} onChange={handleChange} />
                                        </div> */}
                                        {/* Conditional rendering based on useExternalLink state */}
                                        {!useExternalLink && (
                                             <div className='col-9'>
                                                  <label htmlFor="url" className="form-label">Url</label><br />
                                                  <input type="text" name="url" className="form-control" id="url" placeholder="URL" value={formData.url} onChange={handleChange} />
                                             </div>
                                        )}
                                        {useExternalLink && (
                                             <div className='col-9'>
                                                  <label htmlFor="externalUrl" className="form-label">External URL <small>(*Add any link you would like the blog to navigate to)</small></label><br />
                                                  <input type="text" name="externalUrl" className="form-control" id="externalUrl" placeholder="Ex: https://www.google.com" value={formData.externalUrl} onChange={handleChange} />
                                             </div>
                                        )}
                                        <div className='col-3'>
                                             <div className="form-check">
                                                  <br />
                                                  <input type="checkbox" className="form-check-input" id="useExternalLink" checked={useExternalLink} onChange={handleExternalLinkCheckboxChange} />
                                                  <label htmlFor="useExternalLink" className="form-check-label">Use External Link</label><br />
                                             </div>
                                        </div>
                                        <div className='col-12'>
                                             <label htmlFor="author" className="form-label">Author</label><br />
                                             <input type="text" name="author" className="form-control" id="author" placeholder="URL" value={formData.author} onChange={handleChange} />
                                        </div>
                                        <div className='col-12'>
                                             <label htmlFor="dateTime" className="form-label">Published Date</label><br />
                                             <input type="date" className="form-control" id="dateTime" value={dateTime} onChange={handleChangeDateTime} />
                                        </div>
                                        {/* Creatable multi-select dropdown for tags */}
                                        <div className='col-12'>
                                             <label htmlFor="tags" className="form-label">Tags</label><br />
                                             <CreatableSelect
                                                  isMulti
                                                  onChange={handleTagChange}
                                                  options={tagOptions} // The options available for selection
                                                  placeholder="Select or create tags"
                                                  value={formData.tags.map(tag => (typeof tag === 'object') ? tag : { value: tag, label: tag })} // Ensuring the correct format
                                             />
                                        </div>
                                        <div className='col-12'>
                                             <div className="form-check">
                                                  <input type="checkbox" name="isPublished" className="form-check-input" id="isPublished" checked={formData.isPublished} onChange={handleChange} />
                                                  <label htmlFor="isPublished" className="form-check-label">Is Published</label><br />
                                             </div>
                                        </div>
                                        <div className='col-12 text-right'>
                                             <button className='btn btn-primary' type="submit">Create</button>
                                        </div>
                                   </form>
                              </div>
                         </div>
                    </div>
               </div>

               <div className="modal fade" id="editPostModal" tabIndex="-1" aria-labelledby="editPostLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                         <div className="modal-content">
                              <div className="modal-header">
                                   <h5 className="modal-title">Edit Post</h5>
                                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                                   <form className='row g-3' onSubmit={(e) => {
                                        e.preventDefault();
                                        handleUpdate();
                                   }}>
                                        <div className='col-12'>
                                             <label htmlFor="thumbnail" className="form-label">Page Thumbnail</label><br />
                                             <div className='d-flex py-5 align-items-center justify-content-center border' style={{ height: '250px', position: 'relative', overflow: 'hidden' }}>
                                                  {formData.thumbnail ? (
                                                       <img src={formData.thumbnail} alt="Uploaded Thumbnail" style={{ width: '100%', height: '100%', position: 'absolute', objectFit: 'contain' }} />
                                                  ) : (
                                                       <span style={{ fontSize: '50px' }}><i className="bi bi-image"></i></span>
                                                  )}
                                             </div>
                                             <input type="file" name='thumbnail' id="thumbnail" onChange={handleFileChange} className='d-none' />

                                             {/* Button to re-upload image */}
                                             <button
                                                  onClick={(event) => {
                                                       event.preventDefault(); // Prevent form submission
                                                       document.getElementById('thumbnail').click();
                                                  }}
                                                  className="btn btn-sm btn-outline-success d-block mt-2 mx-auto">
                                                  Choose file
                                             </button>
                                        </div>
                                        <div className='col-12'>
                                             <label htmlFor="title" className="form-label">Page Title</label><br />
                                             <input type="text" name="title" placeholder="Title" className="form-control" id="title" value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} />
                                        </div>
                                        <div className='col-12'>
                                             <label htmlFor="description" className="form-label">Description</label><br />
                                             <textarea type="text" name="description" placeholder="Description" className="form-control" id="description" value={formData.description} onChange={handleChange} />
                                        </div>
                                        {!useExternalLink && (
                                             <div className='col-9'>
                                                  <label htmlFor="url" className="form-label">Url</label><br />
                                                  <input type="text" name="url" className="form-control" id="url" placeholder="URL" value={formData.url} onChange={handleChange} />
                                             </div>
                                        )}
                                        {useExternalLink && (
                                             <div className='col-9'>
                                                  <label htmlFor="externalUrl" className="form-label">External URL <small>(*Add any link you would like the blog to navigate to)</small></label><br />
                                                  <input type="text" name="externalUrl" className="form-control" id="externalUrl" placeholder="Ex: https://www.google.com" value={formData.externalUrl} onChange={handleChange} />
                                             </div>
                                        )}
                                        <div className='col-3'>
                                             <div className="form-check">
                                                  <br />
                                                  <input type="checkbox" className="form-check-input" id="useExternalLink" checked={useExternalLink} onChange={handleExternalLinkCheckboxChange} />
                                                  <label htmlFor="useExternalLink" className="form-check-label">Use External Link</label><br />
                                             </div>
                                        </div>
                                        <div className='col-12'>
                                             <label htmlFor="author" className="form-label">Author</label><br />
                                             <input type="text" name="author" className="form-control" id="author" placeholder="URL" value={formData.author} onChange={handleChange} />
                                        </div>
                                        <div className='col-12'>
                                             <label htmlFor="dateTime" className="form-label">Published Date</label><br />
                                             <input type="date" className="form-control" id="dateTime" value={formData.publishedOn} onChange={(e) => setFormData({ ...formData, publishedOn: e.target.value })} />
                                        </div>
                                        <div className='col-12'>
                                             <label htmlFor="tags" className="form-label">Tags</label><br />
                                             <CreatableSelect
                                                  isMulti
                                                  onChange={handleTagChange}
                                                  options={tagOptions} // Make sure tagOptions is an array of { value: string, label: string } objects
                                                  placeholder="Select or create tags"
                                                  value={formData.tags} // formData.tags should be in the same format as options
                                             />

                                        </div>
                                        <div className='col-12'>
                                             <div className="form-check">
                                                  <input type="checkbox" name="isPublished" className="form-check-input" id="isPublished" checked={formData.isPublished} onChange={handleChange} />
                                                  <label htmlFor="isPublished" className="form-check-label">Is Published</label><br />
                                             </div>
                                        </div>
                                        <div className='col-12 text-right'>
                                             <button type="submit" className='btn btn-primary'>Save</button>
                                        </div>
                                   </form>
                              </div>
                         </div>
                    </div>
               </div>

               {/* Posts table */}
               <table className="table">
                    <thead>
                         <tr className='post-header'>
                              <th className='d-none' scope="col">ID</th>
                              <th scope="col" width="100">Thumbnail</th>
                              <th scope="col" width="250">Title</th>
                              <th scope="col">Description</th>
                              <th scope="col">Url</th>
                              <th scope="col" width="150">Author</th>
                              <th scope="col" width="130">Published On</th>
                              <th scope="col" width="130">Published</th>
                              <th scope="col"></th>
                         </tr>
                    </thead>
                    <tbody>
                         {posts.map(post => (
                              <tr key={post.id} className='post-row'>
                                   <td className='d-none'><span>{post.id}</span></td>
                                   <td>
                                        {post.thumbnail ? (
                                             <img src={post.thumbnail} alt="Thumbnail" style={{ width: '100px', height: '50px', objectFit: 'contain' }} />
                                        ) : (
                                             <img src="https://place-hold.it/1920x1080/f1f1f1/333333&bold&fontsize=150&text=Placeholder" alt="Thumbnail" style={{ width: '100px', height: '50px', objectFit: 'contain' }} />
                                        )}
                                   </td>
                                   <td><span>{post.title}</span></td>
                                   <td className='post-description'><span>{post.description}</span></td>
                                   <td><span>{post.url}</span></td>
                                   <td><span>{post.author}</span></td>
                                   <td><span>{post.publishedOn}</span></td>
                                   <td>
                                        {post.isPublished ? (
                                             <span className='badge text-bg-success' style={{ lineHeight: '2' }}>Published</span>
                                        ) : (
                                             <span className='badge text-bg-warning' style={{ lineHeight: '2' }}>Draft</span>
                                        )}

                                   </td>
                                   <td>
                                        <div className="dropdown edit-dropdown">
                                             <button className="btn btn-secondary btn-circle btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                  <i className="bi bi-three-dots-vertical"></i>
                                             </button>
                                             <ul className="dropdown-menu p-3">
                                                  <li className="d-flex align-items-center">
                                                       <span data-bs-toggle="modal" data-bs-target="#editPostModal" onClick={() => handleEdit(post)} style={{ color: '#007bff' }}>
                                                            <strong>
                                                                 <i className="bi bi-pencil-square mr-2" style={{ color: '#007bff' }}></i>
                                                            </strong>
                                                            Edit
                                                       </span>
                                                  </li>
                                                  {!post.externalUrl && (
                                                       <li className="d-flex align-items-center">
                                                            <span onClick={() => handleView(post.id)} style={{ color: '#007bff' }}>
                                                                 <strong>
                                                                      <i className="bi bi-eye mr-2" style={{ color: '#28a745' }}></i>
                                                                 </strong>
                                                                 View
                                                            </span>
                                                       </li>
                                                  )}
                                                  <li className="d-flex align-items-center">
                                                       <span onClick={() => handleRemove(post.id)} style={{ color: '#007bff' }}>
                                                            <strong>
                                                                 <i className="bi bi-trash3 mr-2" style={{ color: '#dc3545' }}></i>
                                                            </strong>
                                                            Delete
                                                       </span>
                                                  </li>
                                             </ul>
                                        </div>
                                   </td>
                              </tr>
                         ))}
                    </tbody>
               </table>
          </div>
     );
}

export default Posts;
