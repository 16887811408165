export default class ColumnTune {
     static get isTune() {
          return true;
     }

     static get DEFAULT_CLASS() {
          return {
               activeClass: '',
          }
     }

     getClasses() {
          let activeClass = ColumnTune.DEFAULT_CLASS;
          if (this.settings && this.settings.default) {
               activeClass = this.settings.default;
          }
          return activeClass;
     }

     constructor({ api, block, data }) {
          this.api = api;
          this.block = block;
          this.wrapper = null;

          const getClasses = this.getClasses();

          this.settings = {
               default: getClasses
          };

          this.data = {
               activeClass: data ? data.activeClass : getClasses.activeClass,
          };

          // At this point, block.holder is undefined
     }

     render() {
          this.wrapper = document.createElement('div');
          this.wrapper.className = 'column-size-tune-wrapper mt-2 mb-2';
          this.wrapper.style.display = 'grid';
          this.wrapper.style.gridTemplateColumns = '1fr 1fr';
          this.wrapper.style.gridColumnGap = '5px';

          // Create 50% Button
          this.button50 = document.createElement('button');
          this.button50.innerText = '50%';
          this.button50.className = 'btn btn-sm btn-outline-primary';
          this.button50.addEventListener('click', () => {
               this.toggleClass('col-6');
               this.data.activeClass = 'col-6' ? 'col-6' : '';
               this.updateBlockStyle('col-6');
          });

          // Create 33% Button
          this.button33 = document.createElement('button');
          this.button33.innerText = '33%';
          this.button33.className = 'btn btn-sm btn-outline-primary';
          this.button33.addEventListener('click', () => {
               this.toggleClass('col-4');
               this.data.activeClass = 'col-4' ? 'col-4' : '';
               this.updateBlockStyle('col-4');
          });

          this.wrapper.appendChild(this.button50);
          this.wrapper.appendChild(this.button33);

          console.log('Render column data:', this.data);  // Debug log

          return this.wrapper;
     }

     toggleClass(className) {
          console.log('Toggling class:', className); // Log the class name being toggled
          const blockElement = document.querySelector(`.ce-block[data-id="${this.block.id}"]`);
          if (blockElement) {
               console.log('Found block element for toggling class'); // Confirm the block element is found
               blockElement.classList.remove('col-6', 'col-4');

               if (this.activeClass !== className) {
                    blockElement.classList.add(className);
                    this.activeClass = className;
                    this.data.activeClass = className;
                    console.log('Class added:', className); // Log class addition
               } else {
                    this.activeClass = '';
                    this.data.activeClass = '';
                    console.log('Class removed:', className); // Log class removal
               }

               this.updateButtonState();
          } else {
               console.log('Block element not found for toggling class'); // Log if block element is not found
          }
     }

     updateButtonState() {
          if (this.button50 && this.button33) {
               this.button50.classList.toggle('active', this.activeClass === 'col-6');
               this.button33.classList.toggle('active', this.activeClass === 'col-4');
          }
     }

     updateBlockStyle(className) {
          console.log('Updating block style with class:', className); // Log the class being applied for styling
          const blockElement = document.querySelector(`.ce-block[data-id="${this.block.id}"]`);
          if (blockElement) {
               blockElement.classList.add(className);
               this.activeClass = className;
               console.log('Block style updated with class:', className); // Confirm class application for styling
          } else {
               console.log('Block element not found for updating style'); // Log if block element is not found
          }
     }

     wrap(blockContent) {
          // Delay the execution to wait for the block.holder to be fully initialized
          setTimeout(() => {
               if (this.data.activeClass) {
                    // Find the closest .ce-block ancestor of blockContent
                    let ceBlock = blockContent.closest('.ce-block');
                    if (ceBlock) {
                         ceBlock.classList.add(this.data.activeClass);
                    } else {
                         console.error('No .ce-block ancestor found for blockContent:', blockContent);
                    }
               }
          }, 10); // Even a 0ms timeout can push the execution to the end of the call stack

          return blockContent;
     }


     save() {
          return this.data;
     }

     static get sanitize() {
          return {
               activeClass: {}
          };
     }
}
