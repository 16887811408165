import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import '../../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';

//Assets Import
import BannerBackground from '../images/Backgrounds-04.jpg';
import OverviewPicture from '../images/overview.png';
import UnderstandWorkPicture from '../images/understand-work.png';
import ExecuteWorkPicture from '../images/execute-work.png';
import ImproveWorkPicture from '../images/improve-work.png';
import BannerBackgroundBlue from '../images/Backgrounds-02.jpg';
import P2PGluu from '../images/p2p-gluu.png';

//Client import
import Client1 from '../images/clients/allianz.png';
import Client2 from '../images/clients/micron.png';
import Client3 from '../images/clients/nedbank.png';
import Client4 from '../images/clients/pep.png';
import Client5 from '../images/clients/pepkor.png';
import Client6 from '../images/clients/pwc.png';
import Client7 from '../images/clients/sanlam.png';
import Client8 from '../images/clients/santam.png';
import Client9 from '../images/clients/woolworths.png';

function Home({ sectionTop, section1Ref, section2Ref, section3Ref, section4Ref, section5Ref, section6Ref }) {
     const [isSmallScreen, setIsSmallScreen] = useState(false);

     useEffect(() => {
          // Function to check the window width and update state
          const handleResize = () => {
               setIsSmallScreen(window.innerWidth <= 991);
          };

          // Initial check on component mount
          handleResize();

          // Add event listener for resize events
          window.addEventListener('resize', handleResize);

          // Remove event listener on component unmount
          return () => {
               window.removeEventListener('resize', handleResize);
          };
     }, [sectionTop, section1Ref, section2Ref, section3Ref, section4Ref, section5Ref, section6Ref]);

     const owlOptions = {
          margin: 10,
          items: 9,
          responsiveClass: true,
          nav: false,
          dots: true,
          autoplay: true,
          smartSpeed: 1000,
          responsive: {
               0: {
                    items: 3,
               },
               400: {
                    items: 4,
               },
               600: {
                    items: 5,
               },
               768: {
                    items: 6,
               },
               1000: {
                    items: 8,
               },
               1366: {
                    items: 9,
               }
          },
     };

     return (
          <div className="wrapper" ref={sectionTop}>
               <section className="module-cover parallax text-center fullscreen" data-background={BannerBackground} data-overlay="0.6" style={{ backgroundImage: 'none', zIndex: 0 }}>
                    <div className="container">
                         <div className="row">
                              <div className="col-md-12">
                                   <h1 className="m-b-20"><strong>Any process<br /> can be improved</strong></h1>
                                   <p className="m-b-40 lead">We're a boutique consultancy streamlining your processes with <a href='https://gluu.biz/' target='_blank' rel='noreferrer' className='gluu-link'>Gluu</a>, a user-friendly<br /> Business Process Management (BPM) platform.</p>
                                   <a href="#contact-us" type="button" data-bs-toggle="offcanvas" data-bs-target="#contact-us" aria-controls="contact-us" className="btn btn-brand btn-circle">Get in touch</a>
                              </div>
                         </div>
                    </div>
                    <div id="jarallax-container-0" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', overflow: 'hidden', zIndex: '-100' }}>
                         {/* <video autoPlay loop muted style={{ width: '100%', height: '100%', objectFit: 'cover', pointerEvents: 'none', }} >
                         <source src={BannerVideo} type="video/mp4" />
                         Your browser does not support the video tag.
                    </video> */}
                         <div style={{ backgroundPosition: '50% 50%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(${BannerBackground})`, position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', overflow: 'hidden', pointerEvents: 'none', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', willChange: 'transform, opacity', marginTop: '0px', transform: 'translate3d(0px, 0px, 0px)' }}></div>
                    </div>
               </section>

               <section className="module" ref={section1Ref} style={{ background: '#0a1433', padding: '120px 0 100px' }}>
                    <div className="space" data-my="-280px" style={{ marginTop: '-280px' }}></div>
                    <div className="container">
                         <div className="row">
                              <div className='col-md-12'>
                                   <p className="m-b-40 text-center" style={{ color: '#fafafa' }}>
                                        Companies where we have actively participated <br /> in business architecture and process management.
                                   </p>
                              </div>
                              <div className="col-md-12" style={{ position: 'relative', background: '#fafafa', border: '1px solid #fafafa', textAlign: 'center', margin: '0 0 30px', borderRadius: '0.1875rem' }}>
                                   <OwlCarousel className="slider-items owl-carousel" {...owlOptions}>
                                        <div className="client company-logo">
                                             {/* <img src={Client1} className="d-block mx-auto img-fluid" alt="Allianz-Logo" loading="lazy" /> */}
                                             <span className='lh-1'><strong>Allianz</strong></span>
                                        </div>

                                        <div className="client company-logo">
                                             {/* <img src={Client2} className="d-block mx-auto img-fluid" alt="Micron-Logo" loading="lazy" /> */}
                                             <span className='lh-1'><strong>Micron</strong></span>
                                        </div>

                                        <div className="client company-logo">
                                             {/* <img src={Client3} className="d-block mx-auto img-fluid" alt="Nedbank-Logo" loading="lazy" /> */}
                                             <span className='lh-1'><strong>Nedbank</strong></span>
                                        </div>

                                        <div className="client company-logo">
                                             {/* <img src={Client4} className="d-block mx-auto img-fluid" alt="Pep-Logo" loading="lazy" /> */}
                                             <span className='lh-1'><strong>PEP</strong></span>
                                        </div>

                                        <div className="client company-logo">
                                             {/* <img src={Client5} className="d-block mx-auto img-fluid" alt="Pepkor-Logo" loading="lazy" /> */}
                                             <span className='lh-1'><strong>Pepkor</strong></span>
                                        </div>

                                        <div className="client company-logo">
                                             {/* <img src={Client6} className="d-block mx-auto img-fluid" alt="Pwc-Logo" loading="lazy" /> */}
                                             <span className='lh-1'><strong>PWC</strong></span>
                                        </div>

                                        <div className="client company-logo">
                                             {/* <img src={Client7} className="d-block mx-auto img-fluid" alt="Sanlam-Logo" loading="lazy" /> */}
                                             <span className='lh-1'><strong>Sanlam</strong></span>
                                        </div>

                                        <div className="client company-logo">
                                             {/* <img src={Client8} className="d-block mx-auto img-fluid" alt="Santam-Logo" loading="lazy" /> */}
                                             <span className='lh-1'><strong>Santam</strong></span>
                                        </div>

                                        <div className="client company-logo">
                                             {/* <img src={Client9} className="d-block mx-auto img-fluid" alt="Woolworths-Logo" loading="lazy" /> */}
                                             <span className='lh-1'><strong>Woolworths</strong></span>
                                        </div>
                                   </OwlCarousel>
                              </div>
                         </div>
                    </div>
                    <div className="container px-4 py-5" id='overview'>
                         <div className="row flex-lg-row-reverse align-items-center g-5 pt-5">
                              <div className="col-lg-6">
                                   <img src={OverviewPicture} className="d-block mx-auto img-fluid" alt="P2P Overview" loading="lazy" width={500} />
                              </div>
                              <div className="col-lg-6">
                                   <h2 className="display-5 fw-bold mb-3 section-title" style={{ color: '#fafafa' }}>
                                        OVERVIEW
                                   </h2>
                                   <h4 className="fw-bold mb-3" style={{ color: '#fafafa' }}>Manage processes<br /> consistently using<br /> <a href='https://gluu.biz/' target='_blank' rel='noreferrer' className='gluu-link'>Gluu</a> BPM</h4>
                              </div>
                         </div>
                         <div className="row flex-lg-row align-items-start g-5 py-5">
                              <div className="col-lg-6">
                                   <h4 className="fw-bold mb-3" style={{ color: '#fafafa' }}>What we do</h4>
                                   <p style={{ color: '#fafafa' }}>We collaborate with you to optimize and enhance your organizational workflows, structures, and systems to improve efficiency, agility, and overall performance.</p>
                              </div>
                              <div className="col-lg-6">
                                   <h4 className="fw-bold mb-3" style={{ color: '#fafafa' }}>How we do it</h4>
                                   <p style={{ color: '#fafafa' }}>We equip your team to be able to design and implement streamlined workflows, incorporate technology solutions where needed, and establish performance metrics to monitor and continuously improve performance.</p>
                              </div>
                         </div>
                    </div>
               </section>

               <section className="module" id='understand-work'>
                    <div className="container px-4 py-5">
                         <div className="row flex-lg-row align-items-center g-5">
                              <div className="col-lg-6">
                                   <img src={UnderstandWorkPicture} className="d-block mx-auto img-fluid" alt="P2P Understand Work" loading="lazy" width={500} />
                              </div>
                              <div className="col-lg-6">
                                   <h2 className="display-5 fw-bold mb-3 section-title">
                                        0.1<br /> <i className='fw-light'>UNDERSTAND</i> <br />WORK
                                   </h2>
                                   <h4 className="fw-bold mb-3">Avoid investing in documentation that no one uses</h4>
                                   <p>
                                        <a href='https://gluu.biz/' target='_blank' rel='noreferrer' className='gluu-link'>Gluu</a> lets you turn your company’s business processes into interactive guides for work. Simply let the right people map flows, add work instructions and assets to let everyone understand what should be done, who is responsible and how it should be done.
                                   </p>
                              </div>
                         </div>
                    </div>
                    <div className="container-fluid">
                         <div className="row">
                              <div className="d-lg-flex flex-md-wrap flex-lg-row align-items-start justify-content-center g-5 pb-5">
                                   <div className="design-block">
                                        <div className="circle-point"></div>
                                        <div className="icon-box text-center">
                                             <div className="icon-box-title">
                                                  <h6>PROCESS ARCHITECTURE</h6>
                                             </div>
                                             <div className="icon-box-content">
                                                  <p>Stop disconnected and duplicate processes with a connected, multi-level process hierarchy where each process has clear owners and editors.</p>
                                             </div>
                                        </div>
                                   </div>

                                   <div className={`design-block ${isSmallScreen ? 'even' : ''}`}>
                                        <div className="circle-point"></div>
                                        <div className="icon-box text-center">
                                             <div className="icon-box-title">
                                                  <h6>PROCESS MAPPING</h6>
                                             </div>
                                             <div className="icon-box-content">
                                                  <p>No more confusion about responsibilities <a href='https://gluu.biz/' target='_blank' rel='noreferrer' className='gluu-link'>Gluu's</a> built-in process designer makes it easy to map activities to roles and clarify ‘who does what’.</p>
                                             </div>
                                        </div>
                                   </div>

                                   <div className="design-block">
                                        <div className="circle-point"></div>
                                        <div className="icon-box text-center">
                                             <div className="icon-box-title">
                                                  <h6>WORK INSTRUCTIONS</h6>
                                             </div>
                                             <div className="icon-box-content">
                                                  <p>Avoid unrelated work- process instructions. Share videos, SOPs, screenshots and files to turn your processes into interactive standard working guides.</p>
                                             </div>
                                        </div>
                                   </div>

                                   <div className={`design-block ${isSmallScreen ? 'even' : ''}`}>
                                        <div className="circle-point"></div>
                                        <div className="icon-box text-center">
                                             <div className="icon-box-title">
                                                  <h6>CHANGE COMMUNICATIONS</h6>
                                             </div>
                                             <div className="icon-box-content">
                                                  <p>7x faster from getting feedback to notifying the right colleagues about a change. A closed-loop flow from change suggestion, discussion, approval and communicating the change to the right users.</p>
                                             </div>
                                        </div>
                                   </div>

                                   <div className="design-block">
                                        <div className="circle-point"></div>
                                        <div className="icon-box text-center">
                                             <div className="icon-box-title">
                                                  <h6>CHANGE CONTROL & RIGHTS</h6>
                                             </div>
                                             <div className="icon-box-content">
                                                  <p>Stop managing processes from the ivory tower. Let different people manage different processes and work instructions – without losing control.</p>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>

               <section className="module" style={{ background: '#f6f6f6' }} id='execute-work'>
                    <div className="container px-4 py-5">
                         <div className="row flex-lg-row align-items-center g-5">
                              <div className="col-lg-6">
                                   <h2 className="display-5 fw-bold mb-3 section-title">
                                        0.2 <br /><i className='fw-light'>EXECUTE</i><br /> WORK
                                   </h2>
                                   <h4 className="fw-bold mb-3">Unsure if work is done right?</h4>
                                   <p>
                                        <a href='https://gluu.biz/' target='_blank' rel='noreferrer' className='gluu-link'>Gluu</a> lets you map and then let the right people run any flow or repeatable task with automatic reporting. This way you can prove that work is done right to customers, authorities and auditors.<br />Some customers run more than 25,000 tasks automatically with <a href='https://gluu.biz/' target='_blank' rel='noreferrer' className='gluu-link'>Gluu</a>, saving 90% of handling and reporting time.
                                   </p>
                              </div>
                              <div className="col-lg-6">
                                   <img src={ExecuteWorkPicture} className="d-block mx-auto img-fluid" alt="P2P Execute Work" loading="lazy" width={400} />
                              </div>
                         </div>
                    </div>
                    <div className="container-fluid">
                         <div className="row">
                              <div className="d-lg-flex flex-md-wrap flex-lg-row align-items-start justify-content-center g-5 pb-5">
                                   <div className="design-block">
                                        <div className="circle-point"></div>
                                        <div className="icon-box text-center">
                                             <div className="icon-box-title">
                                                  <h6>CASE MANAGEMENT</h6>
                                             </div>
                                             <div className="icon-box-content">
                                                  <p>Run end-to-end process flows based on your latest ‘best-practice’. This helps you make sure that every person, system and robot works the right way. Every time.</p>
                                             </div>
                                        </div>
                                   </div>

                                   <div className={`design-block ${isSmallScreen ? 'even' : ''}`}>
                                        <div className="circle-point"></div>
                                        <div className="icon-box text-center">
                                             <div className="icon-box-title">
                                                  <h6>SCHEDULED TASKS</h6>
                                             </div>
                                             <div className="icon-box-content">
                                                  <p><a href='https://gluu.biz/' target='_blank' rel='noreferrer' className='gluu-link'>Gluu</a> lets you manage all scheduled, recurring tasks. Monitor, report and audit with ease so you can feel confident that nothing is forgotten, even if someone is away.</p>
                                             </div>
                                        </div>
                                   </div>

                                   <div className="design-block">
                                        <div className="circle-point"></div>
                                        <div className="icon-box text-center">
                                             <div className="icon-box-title">
                                                  <h6>FORM BUILDER</h6>
                                             </div>
                                             <div className="icon-box-content">
                                                  <p>Stop wasting time by handling paper forms Digitalize paper forms and distribute them with tasks to ensure that registrations are done.</p>
                                             </div>
                                        </div>
                                   </div>

                                   <div className={`design-block ${isSmallScreen ? 'even' : ''}`}>
                                        <div className="circle-point"></div>
                                        <div className="icon-box text-center">
                                             <div className="icon-box-title">
                                                  <h6>APPS FOR ANDROID AND APPLE</h6>
                                             </div>
                                             <div className="icon-box-content">
                                                  <p>No reliance on outdated technology.​ <a href='https://gluu.biz/' target='_blank' rel='noreferrer' className='gluu-link'>Gluu</a> makes it easy to see instructions and complete tasks and forms outside the office.</p>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>

               <section className="module" id='improve-work'>
                    <div className="container px-4 py-5">
                         <div className="row flex-lg-row align-items-center g-5">
                              <div className="col-lg-6">
                                   <img src={ImproveWorkPicture} className="d-block mx-auto img-fluid" alt="P2P Improve Work" loading="lazy" width={400} />
                              </div>
                              <div className="col-lg-6">
                                   <h2 className="display-5 fw-bold mb-3 section-title">
                                        0.3<br /> <i className='fw-light'>IMPROVE</i> <br />WORK
                                   </h2>
                                   <h4 className="fw-bold mb-3">Avoid missing 95% of process improvement opportunities</h4>
                                   <p className="">
                                        <a href='https://gluu.biz/' target='_blank' rel='noreferrer' className='gluu-link'>Gluu</a> transforms process engagement, enabling a seamless information flow from the frontline and back. With empowered users and a culture of continuous improvement, <a href='https://gluu.biz/' target='_blank' rel='noreferrer' className='gluu-link'>Gluu</a> helps you unlock remarkable results. Customers report 10-20x more process improvements.
                                   </p>
                              </div>
                         </div>
                    </div>
                    <div className="container-fluid">
                         <div className="row">
                              <div className="d-lg-flex flex-md-wrap flex-lg-row align-items-start justify-content-center g-5 pb-5">
                                   <div className="design-block">
                                        <div className="circle-point"></div>
                                        <div className="icon-box text-center">
                                             <div className="icon-box-title">
                                                  <h6>MANAGE CHANGE SUGGESTIONS</h6>
                                             </div>
                                             <div className="icon-box-content">
                                                  <p>Encourage business users to ask questions and comment on processes and work instructions. Take good ideas through a closed loop discussion and approval process all the way to a new, improved process or SOP version.​</p>
                                             </div>
                                        </div>
                                   </div>

                                   <div className={`design-block ${isSmallScreen ? 'even' : ''}`}>
                                        <div className="circle-point"></div>
                                        <div className="icon-box text-center">
                                             <div className="icon-box-title">
                                                  <h6>EXPLORE ASSET RELATIONSHIPS</h6>
                                             </div>
                                             <div className="icon-box-content">
                                                  <p>Owners & team members can see how processes are related to assets, like systems, bots, work instructions and standards, making it faster to analyze, understand and mitigate risks, when implementing improvement ideas.​</p>
                                             </div>
                                        </div>
                                   </div>

                                   <div className="design-block">
                                        <div className="circle-point"></div>
                                        <div className="icon-box text-center">
                                             <div className="icon-box-title">
                                                  <h6>ENTERPRISE ARCHITECTURE</h6>
                                             </div>
                                             <div className="icon-box-content">
                                                  <p><a href='https://gluu.biz/' target='_blank' rel='noreferrer' className='gluu-link'>Gluu</a> offers process owners the function of adding assets directly to work instructions for an easier and more accurate maintenance. This results in more valid, structural, and updated information of which and how any assets are used.</p>
                                             </div>
                                        </div>
                                   </div>

                                   <div className={`design-block ${isSmallScreen ? 'even' : ''}`}>
                                        <div className="circle-point"></div>
                                        <div className="icon-box text-center">
                                             <div className="icon-box-title">
                                                  <h6>IMPLEMENT END-TO-END GOVERNANCE</h6>
                                             </div>
                                             <div className="icon-box-content">
                                                  <p><a href='https://gluu.biz/' target='_blank' rel='noreferrer' className='gluu-link'>Gluu</a> is an all-in-one tool for Process Excellence teams. From managing a process hierarchy, to process owners, changes, suggestions, approvals and communications. A holistic approach that includes processes, work instructions and assets.</p>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>

               {/* <section className="module" ref={section5Ref}>
                 <div className="container px-4 py-5">
                    <div className="row flex-lg-row align-items-center g-5">
                         <div className="col-lg-6">
                              <h2 className="display-5 fw-bold mb-3 section-title">
                                   0.4 VALUE <br/>PROPOSITION
                              </h2>

                              <img src="https://place-hold.it/700x700/e7e7e7/333" className="d-block mx-auto img-fluid" alt="P2P Value Proposition" loading="lazy"/>
                         </div>
                         <div className="col-lg-6">
                              <h1 className="display-5 fw-bold   mb-3">
                                   CUSTOMER <br/>PROFILE
                              </h1>

                              <img src="https://place-hold.it/700x700/e7e7e7/333" className="d-block mx-auto img-fluid" alt="P2P Value Proposition" loading="lazy"/>
                         </div>
                    </div>
                 </div>
               </section> */}

               <section className="module" style={{ background: '#0a1433' }} id='about-us'>
                    <div className="container px-4 py-5">
                         <div className="row flex-lg-row align-items-start g-5">
                              <div className="col-lg-12">
                                   <h2 className="display-5 fw-bold mb-3 section-title" style={{ color: '#fafafa' }}>
                                        ABOUT US
                                   </h2>
                                   <h4 className="mb-3" style={{ color: '#fafafa' }}><span className='fw-bold'>Introducing Process2Product</span><br /> Debunking the Myth of Complex BPM Platforms</h4>
                              </div>
                              <div className='col-lg-6'>
                                   <p className="" style={{ color: '#fafafa' }}>
                                        At the core of Process2Product is the conviction that one doesn't require a convoluted and costly business process management (BPM) platform to define, design, execute, and enhance business processes. The belief is simple – streamline your processes to empower everyone to contribute effortlessly.
                                   </p>
                                   <p className="" style={{ color: '#fafafa' }}>
                                        Our journey unfolds over 38 years of collective expertise in business architecture and process management, spanning diverse industries. Throughout this extensive tenure, we engaged with numerous "leading" BPM platforms, only to realize that most catered primarily to expert users. None prioritized the end customer, the frontline worker, and their management.
                                   </p>

                              </div>
                              <div className='col-lg-6'>
                                   <p className="" style={{ color: '#fafafa' }}>
                                        In the quest for an all-encompassing BPM platform, our search commenced in early 2023. After evaluating eight platforms, <a href='https://gluu.biz/' target='_blank' rel='noreferrer' className='gluu-link'>Gluu</a> emerged as the optimal choice, backed by authentic end-user feedback. Now an official <a href='https://gluu.biz/' target='_blank' rel='noreferrer' className='gluu-link'>Gluu</a> partner, we are eager to explore how we can help optimize your business processes.
                                   </p>
                                   <p className="" style={{ color: '#fafafa' }}>
                                        A pivotal criterion for us was whether the vendor utilized their tool internally for managing, executing, and improving work. Surprisingly, this was not often the case!
                                   </p>
                                   <p className="" style={{ color: '#fafafa' }}>
                                        Join us as we recount our journey and delve into our active participation in business architecture and process management across various companies. Let Process2Product be your guide in simplifying and enhancing your business processes.
                                   </p>
                                   {/* <p className="" style={{color: '#fafafa'}}>
                                   Here are some of the companies where we have actively participated in business architecture and process management.
                              </p> */}
                              </div>
                         </div>
                    </div>
               </section>

               <section className="module-cover py-5" data-background={BannerBackgroundBlue} data-overlay="0.6" style={{ backgroundImage: 'none', zIndex: 0 }}>
                    <div className="container">
                         <div className="row justify-content-center">
                              <div className="col-md-12">
                                   <img src={P2PGluu} className="d-block mx-auto img-fluid" alt="P2P & Gluu logo" loading="lazy" width={700} />
                              </div>
                         </div>
                    </div>
                    <div id="jarallax-container-0" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', overflow: 'hidden', zIndex: '-100' }}>
                         <div style={{ backgroundPosition: '50% 50%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(${BannerBackgroundBlue})`, position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', overflow: 'hidden', pointerEvents: 'none', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', willChange: 'transform, opacity', marginTop: '0px', transform: 'translate3d(0px, 0px, 0px)' }}></div>
                    </div>
               </section>
          </div>
     );
}

export default Home;