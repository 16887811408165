import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Header from '../public_layout/shared/Header';
import Home from "../public_layout/pages/Home";
import Blogpost from "../public_layout/pages/Blogpost";
import Bloglist from "../public_layout/pages/Bloglist";
import Contact from "../public_layout/pages/Contact";
import Postslist from './pages/Postslist';
import Postpage from './pages/Postpage';

function PublicLayout() {
     const [scroll, setScroll] = useState(false);
     const [isTabletScreen, setTabletScreen] = useState(false);
     const navigate = useNavigate();

     useEffect(() => {
          window.addEventListener("scroll", () => {
               setScroll(window.scrollY > 50);
          });

          // Function to check the window width and update state
          const handleResize = () => {
               setTabletScreen(window.innerWidth <= 991);
          };

          // Initial check on component mount
          handleResize();

          // Add event listener for resize events
          window.addEventListener('resize', handleResize);

          // Remove event listener on component unmount
          return () => {
               window.removeEventListener('resize', handleResize);
          };
     }, []);

     const sectionTop = useRef(null);
     const section1Ref = useRef(null);
     const section2Ref = useRef(null);
     const section3Ref = useRef(null);
     const section4Ref = useRef(null);
     const section5Ref = useRef(null);
     const section6Ref = useRef(null);

     const handleScrollToSection = (ref) => {
          if (ref.current) {
               ref.current.scrollIntoView({ behavior: 'smooth' });
          }
     };

     return (
          <main ref={sectionTop}>
               <>
                    <Header />
                    <Routes>
                         <Route
                              exact
                              path="/"
                              element={
                                   <Home
                                        sectionTop={sectionTop}
                                        section1Ref={section1Ref}
                                        section2Ref={section2Ref}
                                        section3Ref={section3Ref}
                                        section4Ref={section4Ref}
                                        section5Ref={section5Ref}
                                        section6Ref={section6Ref}
                                   />
                              }
                         />
                         <Route
                              path="/blogs"
                              exact
                              element={<Postslist />}
                         />
                         <Route
                              path="/blogs/:url"
                              exact
                              element={<Postpage />}
                         />
                    </Routes>

                    <Contact />
                    <div className="container">
                         <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4">
                              <div className="col-md-6 d-flex align-items-center justify-content-md-start justify-content-center">
                                   <span className="mb-3 mb-md-0 text-body-secondary">Copyright © 2023 Process To Product, All rights reserved</span>
                              </div>

                              <ul className="nav col-md-6 justify-content-md-end justify-content-center list-unstyled d-flex">
                                   Follow Us:
                                   <li className="ms-3"><a className="text-body-secondary" href="https://www.linkedin.com/in/etienne-venter-15185131/" target='_blank' rel='noreferrer'><i className="bi bi-linkedin"></i></a></li>
                              </ul>
                         </footer>
                    </div>

                    <a className={scroll ? "scroll-top scroll-top-visible" : "scroll-top"} onClick={() => handleScrollToSection(sectionTop)}><i className="bi bi-chevron-up"></i></a>
               </>
          </main>
     );
}

export default PublicLayout