// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcUgXaZi4DSHut3aWNg-UElZUTX_OX-tg",
  authDomain: "p2p-reactapp-e6aaa.firebaseapp.com",
  projectId: "p2p-reactapp-e6aaa",
  storageBucket: "p2p-reactapp-e6aaa.appspot.com",
  messagingSenderId: "435152673765",
  appId: "1:435152673765:web:2e6213e02b55cf53a2ed0c",
  measurementId: "G-8RR1Q3E8NS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);

export { auth, db, storage };