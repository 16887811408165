import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import NestedList from '@editorjs/nested-list';
import Alert from 'editorjs-alert';
import Paragraph from '@editorjs/paragraph';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';
import ImageTool from '@editorjs/image';
import MarginBlockTune from './MarginTune';
import ColorPlugin from 'editorjs-text-color-plugin';
import Checklist from '@editorjs/checklist';
import Hyperlink from 'editorjs-hyperlink';
import Strikethrough from '@sotaproject/strikethrough';
import DragDrop from 'editorjs-drag-drop';

//Firebase Db
import { storage } from "../../../../firebase";
import { ref as storageRef, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";

class ThreeColumns {
     static get toolbox() {
          return {
               title: '3 Columns',
               icon: '<svg xmlns="http://www.w3.org/2000/svg" style="width:12px;" width="16" height="16" fill="currentColor" className="bi bi-layout-split" viewBox="0 0 16 16"> <path d="M0 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm8.5-1v12H14a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zm-1 0H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h5.5z"/> </svg>',
          };
     }

     constructor({ data, api }) {
          this.api = api;
          this.data = data || { cols: [{}, {}] }; // Initialize with three empty objects if data is not provided
          this.state = {
               uploadedImages: {
                    colThree1: [],
                    colThree2: [],
                    colThree3: [],
               }
          };
          //console.log('ThreeColumns data:', this.data); // Check the structure of the data object
     }

     render() {
          // Creating the main container
          const container = document.createElement('div');
          container.className = 'three-columns-container nestable-editor';
          container.style.display = 'flex';
          container.style.columnGap = '30px';

          // Create three column containers
          // Ensure data.cols is an array with at least three elements
          this.data.cols = this.data.cols || [{}, {}, {}];
          const colThree1 = this.createColumnThree('colThree1', this.data.cols[0]);
          const colThree2 = this.createColumnThree('colThree2', this.data.cols[1]);
          const colThree3 = this.createColumnThree('colThree3', this.data.cols[2]);

          // Append columns to the main container
          container.appendChild(colThree1);
          container.appendChild(colThree2);
          container.appendChild(colThree3);

          return container;
     }

     createColumnThree(id, colThreeData = { content: [] }) {
          const columnThree = document.createElement('div');
          columnThree.className = `column-three nestable-editor ${id}`;
          columnThree.style.width = '33.33%';

          const editorContainer = document.createElement('div');
          editorContainer.id = `threecolumn-editor-${id}-${this._generateUniqueSuffix()}`; // Ensure unique ID
          editorContainer.className = 'nestable-editor';
          columnThree.appendChild(editorContainer);

          // Check if colData has content and if it's an array
          const contentBlocks = colThreeData && Array.isArray(colThreeData.content) ? colThreeData.content : [];

          const main_tools = {
               header: {
                    class: Header,
                    inlineToolbar: ['bold', 'italic', 'hyperlink', 'strikethrough', 'Color'],
                    tunes: ['anyTuneName', 'anyTune'],
                    autofocus: false,
                    config: {
                         placeholder: 'Heading'
                    },
               },
               paragraph: {
                    class: Paragraph,
                    inlineToolbar: ['bold', 'italic', 'hyperlink', 'strikethrough', 'Color'],
                    tunes: ['anyTuneName', 'anyTune'],
                    autofocus: false,
               },
               strikethrough: Strikethrough,
               image: {
                    class: ImageTool,
                    config: {
                         uploader: {
                              uploadByFile: (file) => {
                                   console.log('Uploading file:', file.name); // Check if the function is called
                                   return new Promise((resolve, reject) => {
                                        const fileRef = storageRef(storage, `images/${file.name}`);
                                        const uploadTask = uploadBytesResumable(fileRef, file);

                                        uploadTask.on('state_changed',
                                             (snapshot) => {
                                                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                                  console.log(`Upload is ${progress}% done for ${file.name}`);
                                             },
                                             (error) => {
                                                  console.error('Upload error:', error);
                                                  reject('Upload failed: ' + error.message);
                                             },
                                             () => {
                                                  getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                                       console.log('File available at', downloadURL); // Check the download URL
                                                       let newUrl = downloadURL.replace(/&amp;/g, "&");  // Correcting the URL
                                                       resolve({
                                                            success: 1,
                                                            file: { url: newUrl }
                                                       });
                                                  }).catch(error => {
                                                       console.error('Error getting download URL:', error);
                                                       reject('Error getting download URL: ' + error.message);
                                                  });

                                             }
                                        );
                                   });
                              }
                         }
                    }
               },
               list: {
                    class: NestedList,
                    inlineToolbar: ['bold', 'italic', 'hyperlink', 'strikethrough', 'Color'],
                    tunes: ['anyTune'],
                    autofocus: false,
                    config: {
                         placeholder: 'List',
                         defaultStyle: 'unordered'
                    },
               },
               alert: {
                    class: Alert,
                    inlineToolbar: ['bold', 'italic', 'hyperlink', 'strikethrough', 'Color'],
                    tunes: ['anyTune'],
                    autofocus: false,
                    config: {
                         placeholder: 'Alert'
                    },
               },
               checklist: {
                    class: Checklist,
                    inlineToolbar: ['bold', 'italic', 'hyperlink', 'strikethrough', 'Color'],
               },
               Color: {
                    class: ColorPlugin,
                    config: {
                         colorCollections: ["#FFFFFF", "#C0C0C0", "#808080", "#000000", "#FF0000", "#800000", "#FFFF00", "#808000", "#00FF00", "#008000", "#00FFFF", "#008080", "#0000FF", "#000080", "#FF00FF", "#800080"],
                         defaultColor: '#333333',
                         type: 'text',
                         customPicker: true // add a button to allow selecting any colour  
                    }
               },
               hyperlink: {
                    class: Hyperlink,
                    config: {
                         shortcut: 'CMD+L',
                         target: '_blank',
                         rel: 'nofollow',
                         availableTargets: ['_blank', '_self'],
                         availableRels: ['author', 'noreferrer'],
                         validate: false,
                    }
               },
               anyTuneName: {
                    class: AlignmentTuneTool,
                    config: {
                         default: "left",
                    },
               },
               anyTune: {
                    class: MarginBlockTune,
                    config: {
                         default: {
                              marginBottom: "0px",
                              marginTop: "0px"
                         }
                    }
               },
          };

          // Store reference to EditorJS instance
          this[`editor${id}`] = new EditorJS({
               holder: editorContainer.id,
               placeholder: 'Column content goes here...',
               minHeight: 0,
               tools: main_tools,
               tunes: ['anyTune'],
               data: {
                    blocks: contentBlocks,
               },
               onReady: () => {
                    // Initialize the DragDrop module with the editor instance
                    new DragDrop(this[`editor${id}`]);
               },
          });
          return columnThree;
     }

     // Helper function to generate a unique suffix for the ID
     _generateUniqueSuffix() {
          return Date.now().toString(36) + Math.random().toString(36).substr(2);
     }

     // Implement a `save` method required by Editor.js tools
     async save() {
          let colThree1Content = { blocks: [] };
          let colThree2Content = { blocks: [] };
          let colThree3Content = { blocks: [] };

          if (this.editorcolThree1 && typeof this.editorcolThree1.save === 'function') {
               colThree1Content = await this.editorcolThree1.save();
          }

          if (this.editorcolThree2 && typeof this.editorcolThree2.save === 'function') {
               colThree2Content = await this.editorcolThree2.save();
          }

          if (this.editorcolThree3 && typeof this.editorcolThree3.save === 'function') {
               colThree3Content = await this.editorcolThree3.save();
          }

          return {
               type: 'threeColumns',
               cols: [
                    { content: colThree1Content.blocks },
                    { content: colThree2Content.blocks },
                    { content: colThree3Content.blocks }
               ]
          };
     }
}

export default ThreeColumns;
