import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PublicLayout from './components/public_layout/PublicLayout';
import ProtectedLayout from './components/private_layout/ProtectedLayout';
import Login from './components/private_layout/backoffice/pages/login/Login';
import Auth from './components/Auth';

function App() {
    return (
        <Router>
            <Routes>
                {/* Public Routes */}
                <Route path="/*" element={<PublicLayout />} />

                {/* Login Route - Make this a public route */}
                <Route path="/backoffice/login" element={<Login />} />

                {/* Private Routes */}
                <Route path="/backoffice/*" element={
                    <Auth>
                        <ProtectedLayout />
                    </Auth>
                } />
            </Routes>
        </Router>
    );
}

export default App;
