import DOMPurify from 'dompurify';

function Output({ editorContent }) {
     //console.log('Editor Block Output:', editorContent);
     const sanitizeAndRenderHTML = (html) => {
          const sanitizedHTML = DOMPurify.sanitize(html, {
               ALLOWED_TAGS: ['br', 'b', 'i', 'font', 'a', 's', 'span'],
               ADD_ATTR: ['target', 'style'],
          });
          return { __html: sanitizedHTML };
     };


     const renderBlock = (block) => {
          const tunes = block.tunes || {};
          const anyTuneName = tunes.anyTuneName || {};
          const marginTune = tunes.anyTune || {};
          // Extracting the spacing values
          const blockStyle = {
               marginTop: marginTune.marginTop,
               marginBottom: marginTune.marginBottom,
          };

          switch (block.type) {
               case 'header':
                    const HeadingComponent = `h${block.data.level || 1}`;
                    const HeadingTune = anyTuneName.alignment || 'left';
                    return <HeadingComponent key={block.id} className={`ce-header ce-tune-alignment--${HeadingTune}`} style={blockStyle} dangerouslySetInnerHTML={sanitizeAndRenderHTML(block.data.text)} />;
               case 'paragraph':
                    const ParagraphTune = anyTuneName.alignment || 'left';
                    const paragraphHTML = sanitizeAndRenderHTML(block.data.text).__html;
                    return <div className={`ce-paragraph cdx-block ce-tune-alignment--${ParagraphTune}`} style={blockStyle} dangerouslySetInnerHTML={{ __html: paragraphHTML }} />;
               case 'image':
                    const { url } = block.data.file;
                    const decodedUrl = decodeHtmlEntity(url);
                    const altText = block.data.caption || 'Image';
                    const withBorder = block.data.withBorder ? 'image-tool--withBorder' : '';
                    const withBackground = block.data.withBackground ? 'image-tool--withBackground' : '';
                    const stretched = block.data.stretched ? 'image-tool--stretched' : '';
                    return (
                         <div className={`cdx-block image-tool image-tool--filled ${withBorder} ${stretched} ${withBackground}`} style={blockStyle}>
                              <div className='image-tool__image'>
                                   <img className="image-tool__image-picture" src={decodedUrl} alt={altText} />
                              </div>
                         </div>
                    );
               case 'list':
                    const ListComponent = block.data.type === 'list' ? 'ul' : 'ol';
                    const ListStyle = block.data.style === 'unordered' ? 'unordered' : 'ordered';
                    //const ListTune = anyTuneName.alignment || 'left';
                    const renderListItems = (items, level = 0) => {
                         return items.map((nestedItem, index) => (
                              <li key={index} className='cdx-nested-list__item'>
                                   <div className='cdx-nested-list__item-body'>
                                        <div className='cdx-nested-list__item-content' dangerouslySetInnerHTML={sanitizeAndRenderHTML(nestedItem.content)}></div>
                                        {nestedItem.items && nestedItem.items.length > 0 && (
                                             <ListComponent className={`cdx-nested-list cdx-nested-list__item-children cdx-nested-list--${ListStyle}`}>
                                                  {renderListItems(nestedItem.items, level + 1)}
                                             </ListComponent>
                                        )}
                                   </div>
                              </li>
                         ));
                    };
                    return (
                         <div className={`cdx-block cdx-nested-list ${ListStyle === 'ordered' ? 'cdx-nested-list--ordered' : 'cdx-list--unordered'}`} style={blockStyle}>
                              <ListComponent className={`cdx-nested-list cdx-block cdx-nested-list--${ListStyle}`}>
                                   {renderListItems(block.data.items)}
                              </ListComponent>
                         </div>
                    );
               case 'checklist':
                    return (
                         <div className={`cdx-block cdx-checklist`} style={blockStyle}>
                              {block.data.items.map((item, index) => (
                                   <div key={index} className={`cdx-checklist__item ${item.checked ? 'cdx-checklist__item--checked' : ''}`}>
                                        <div className='cdx-checklist__item-checkbox'>
                                             <span className='cdx-checklist__item-checkbox-check'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M7 12L10.4884 15.8372C10.5677 15.9245 10.705 15.9245 10.7844 15.8372L17 9"></path></svg>
                                             </span>
                                        </div>
                                        <div className="cdx-checklist__item-text" dangerouslySetInnerHTML={sanitizeAndRenderHTML(item.text)}>
                                        </div>
                                   </div>
                              ))}
                         </div>
                    );
               case 'alert':
                    const alertType = block.data.type || 'info';
                    const alertAlign = block.data.align || 'left';
                    return (
                         <div key={block.id} className={`cdx-alert cdx-alert-${alertType} cdx-alert-align-${alertAlign}`} style={blockStyle}>
                              <div className="cdx-alert__message" contentEditable="false" data-placeholder="Type here..." dangerouslySetInnerHTML={sanitizeAndRenderHTML(block.data.message || '')} />
                         </div>
                    );
               case 'columns':
                    return renderTwoColumns(block.data.cols);
               case 'threeColumns':
                    return renderThreeColumns(block.data.cols);
               default:
                    return null;
          }
     };

     // Helper function to decode HTML entities
     function decodeHtmlEntity(str) {
          return str.replace(/&amp;/g, '&');
     }

     const renderTwoColumns = (cols) => {
          if (!cols || cols.length === 0) {
               return null;
          }

          return (
               <div className="row">
                    {cols.map((col, colIndex) => (
                         <div key={colIndex} className="col-md-6">
                              {col.content && col.content.map((block, blockIndex) => (
                                   <div key={blockIndex} className="ce-column__block">
                                        {renderBlock(block)}
                                   </div>
                              ))}
                         </div>
                    ))}
               </div>
          );
     };

     const renderThreeColumns = (cols) => {
          if (!cols || cols.length === 0) {
               return null;
          }

          return (
               <div className="row">
                    {cols.map((col, colIndex) => (
                         <div key={colIndex} className="col-md-4">
                              {col.content && col.content.map((block, blockIndex) => (
                                   <div key={blockIndex} className="ce-column__block">
                                        {renderBlock(block)}
                                   </div>
                              ))}
                         </div>
                    ))}
               </div>
          );
     };

     return (
          <div className='container'>
               <div className="row">
                    {editorContent && editorContent.blocks && editorContent.blocks.length > 0 ? (
                         <>
                              {editorContent.blocks.map((block) => {
                                   // Extract activeClass from the block's tunes data
                                   const activeClass = block.tunes?.columnTune?.activeClass || '';

                                   return (
                                        <div className={`ce-block ${activeClass}`} key={block.id}>
                                             <div className="ce-block__content">
                                                  {renderBlock(block)}
                                             </div>
                                        </div>
                                   );
                              })}
                         </>
                    ) : (
                         <div className="ce-block"><p>No content to display.</p></div>
                    )}
               </div>
          </div>
     );

}

export default Output;
