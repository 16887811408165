import React, { useState, useEffect } from 'react';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
     const navigate = useNavigate();
     const auth = getAuth();
     const [userName, setUserName] = useState('');

     useEffect(() => {
          // Subscribe to auth state changes
          const unsubscribe = onAuthStateChanged(auth, (user) => {
               if (user) {
                    // Check the user's email and set the userName accordingly
                    switch (user.email) {
                         case 'process4everyone@gmail.com':
                              setUserName('Etienne Venter');
                              break;
                         case 'etienne.venter@process2product.co.za':
                              setUserName('Etienne Venter');
                              break;
                         case 'simonventer970523@gmail.com':
                              setUserName('Simon Venter');
                              break;
                         // Add more cases as needed
                         default:
                              setUserName(user.displayName || user.email); // Fallback to displayName or email
                    }
               } else {
                    // User is signed out, redirect to login
                    navigate('/backoffice/login');
               }
          });

          // Cleanup subscription on unmount
          return () => unsubscribe();
     }, [auth, navigate]);

     const handleSignOut = () => {
          signOut(auth)
               .then(() => {
                    // Sign-out successful.
                    navigate('/backoffice/login'); // Redirect to login page after sign out
               })
               .catch((error) => {
                    // An error happened.
                    console.error('Sign out error', error);
               });
     };

     return (
          <>
               <hr />
               <div className="dropdown">
                    <a href="#" className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                         <i className="bi bi-person-circle mr-3 fs-4"></i>
                         <strong>{userName}</strong>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
                         <li><a className="dropdown-item" href="#" onClick={handleSignOut}><i className="bi bi-box-arrow-left mr-3"></i>Sign out</a></li>
                    </ul>
               </div>
          </>
     )
}

export default Dashboard;
