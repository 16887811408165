import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Dashboard from './backoffice/pages/dashboard/Dashboard';
import Create from './backoffice/pages/posts/Create';
import Login from './backoffice/pages/login/Login';
import Posts_Table from './backoffice/pages/posts/Posts_table';
import './ProtectedLayout.css';

function ProtectedLayout() {
     return (
          <>
               <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
                    <span className="navbar-brand col-md-3 col-lg-2 me-0 px-3">Process 2 Product</span>
               </header>
               <div className="container-fluid">
                    <div className="row">
                         <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                              <div className="position-sticky p-3">
                                   <h6 className="sidebar-heading d-flex justify-content-between align-items-center pr-3 mt-4 mb-1">
                                        <span>Content</span>
                                   </h6>
                                   <ul className="nav nav-pills flex-column mb-auto">
                                        <li className="nav-item">
                                             <Link className="nav-link" to="/backoffice/posts">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-file-text" aria-hidden="true"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                                                  Blogs
                                             </Link>
                                        </li>

                                   </ul>
                                   <Dashboard />

                              </div>
                         </nav>

                         <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                              <Routes>
                                   <Route path="posts" element={<Posts_Table />} />
                                   <Route path="create/:postId" element={<Create />} />
                                   <Route path="login" element={<Login />} />
                              </Routes>
                         </main>
                    </div>
               </div>

          </>
     );
}

export default ProtectedLayout;
