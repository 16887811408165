import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toast } from "react-bootstrap";
import { auth } from "../../../../../firebase"; // Adjust the path as necessary
import { signInWithEmailAndPassword } from "firebase/auth";

function Login() {
     const [loading, setLoading] = useState(false);
     const [showToast, setShowToast] = useState(false);
     const [errorShowToast, setErrorShowToast] = useState(false);

     const [email, setEmail] = useState('');
     const [password, setPassword] = useState('');

     const navigate = useNavigate();

     const handleToastClose = () => {
          setShowToast(false);
          setErrorShowToast(false);
     };

     const handleLogin = async (e) => {
          e.preventDefault();
          try {
               await signInWithEmailAndPassword(auth, email, password);
               console.log("User logged in successfully");
               // Redirect user or update the UI
               navigate('/backoffice');
          } catch (error) {
               console.error("Login error:", error.message);
               setLoading(false);
               setErrorShowToast(true);
               // Calling toast method by passing string
               setErrorShowToast(true);
          }
     };

     return (
          <>
               <section className="module-cover parallax text-center fullscreen" data-background={'https://offsetcode.com/themes/boomerang/1.3/assets/images/module-5.jpg'} data-overlay="0.6" style={{ backgroundImage: 'none', zIndex: 0 }}>
                    <div className="container">
                         <div className="row">
                              <div className="col-lg-4 col-md-6 m-auto">
                                   <div className="m-b-20">
                                        <h6>Sign into your account</h6>
                                   </div>
                                   <div className="m-b-20">
                                        <form onSubmit={handleLogin}>
                                             <div className="form-group">
                                                  <input className="form-control" type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                                             </div>
                                             <div className="form-group">
                                                  <input className="form-control" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                             </div>
                                             <div className="form-group">
                                                  <button type="submit" className="btn btn-block btn-round btn-brand" disabled={loading}>
                                                       {loading ? (
                                                            <>
                                                                 <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                 Loading...
                                                            </>
                                                       ) : (
                                                            'Login'
                                                       )}
                                                  </button>
                                             </div>
                                        </form>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div className="overlay-background" style={{ opacity: '0.6' }}></div>
                    <div id="jarallax-container-0" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', overflow: 'hidden', zIndex: '-100' }}>
                         <div style={{ backgroundPosition: '50% 50%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(https://offsetcode.com/themes/boomerang/1.3/assets/images/module-5.jpg)`, position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', overflow: 'hidden', pointerEvents: 'none', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', willChange: 'transform, opacity', marginTop: '0px', transform: 'translate3d(0px, 0px, 0px)' }}></div>
                    </div>
               </section>

               <Toast show={errorShowToast} onClose={handleToastClose} className="position-fixed top-0 end-0 m-3 bg-danger" autohide="true" delay="5000">
                    <Toast.Header>
                         <strong className="me-auto">Error Submitting</strong>
                    </Toast.Header>
                    <Toast.Body>There was a issue submitting your form.</Toast.Body>
               </Toast>
          </>
     )
}

export default Login