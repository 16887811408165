import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../../firebase';
import { collection, query, where, getDocs } from "firebase/firestore";
import Output from '../../private_layout/backoffice/pages/posts/Output';
import { Helmet } from 'react-helmet';

function Postpage() {
     const { url } = useParams();
     const fullUrl = window.location.href; // Gets the full URL of the current page
     const [postContent, setPostContent] = useState(null);
     const [thumbnail, setThumbnail] = useState("");
     const [postTitle, setPostTitle] = useState("");
     const [postDescription, setPostDescription] = useState("");
     const [postAuthor, setPostAuthor] = useState("");

     useEffect(() => {
          const fetchPostData = async () => {
               const q = query(collection(db, "posts"), where("url", "==", `${url}`));
               const querySnapshot = await getDocs(q);

               if (!querySnapshot.empty) {
                    const docData = querySnapshot.docs[0].data();
                    setPostContent(docData.content);
                    setThumbnail(docData.thumbnail);
                    setPostTitle(docData.title);
                    setPostDescription(docData.description);
                    setPostAuthor(docData.author);
               } else {
                    console.log("No such document with URL:", url);
               }
          };

          fetchPostData();
     }, [url]);

     return (
          <div className="wrapper">
               <Helmet>
                    <title>{`Process 2 Product | ${postTitle}`}</title>
                    <meta name="description" content={postDescription} />
                    <meta name="author" content={postAuthor} />
                    <meta property="og:title" content={`Process 2 Product | ${postTitle}`} />
                    <meta property="og:description" content={postDescription} />
                    <meta property="og:image" content={thumbnail} />
                    <meta property="og:url" content={fullUrl} />
                    <meta name="twitter:title" content={`Process 2 Product | ${postTitle}`} />
                    <meta name="twitter:description" content={postDescription} />
                    <meta name="twitter:image" content={thumbnail} />
                    <meta name="twitter:card" content="summary_large_image" />
               </Helmet>
               <section className="module-cover parallax text-center" data-background={thumbnail} data-overlay="0.6" style={{ background: '#0a1433', backgroundImage: 'none', zIndex: 0 }}>
                    <div id="jarallax-container-0" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', overflow: 'hidden', zIndex: '-100' }}>
                         <div style={{ backgroundPosition: '50% 50%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(${thumbnail})`, position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', overflow: 'hidden', pointerEvents: 'none', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', willChange: 'transform, opacity', marginTop: '0px', transform: 'translate3d(0px, 0px, 0px)' }}></div>
                    </div>
               </section>

               {postContent ?
                    <Output editorContent={{ blocks: postContent }} /> :
                    <div className='container py-5' style={{ minHeight: "500px" }}>
                         <div className='text-center'>
                              <span className="mr-1">Loading</span>
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                         </div>
                    </div>
               }
          </div>
     );
}

export default Postpage;
