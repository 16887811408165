import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LogoBlack from "../images/logo.svg";
import LogoWhite from "../images/logo-white.svg";

function Header() {
     const [scroll, setScroll] = useState(false);
     const [isTabletScreen, setTabletScreen] = useState(false);
     const location = useLocation();

     useEffect(() => {
          window.addEventListener("scroll", () => {
               setScroll(window.scrollY > 50);
          });

          // Function to check the window width and update state
          const handleResize = () => {
               setTabletScreen(window.innerWidth <= 991);
          };

          // Initial check on component mount
          handleResize();

          // Add event listener for resize events
          window.addEventListener('resize', handleResize);

          // Remove event listener on component unmount
          return () => {
               window.removeEventListener('resize', handleResize);
          };
     }, []);

     const isBlogPage = location.pathname.startsWith('/blogs');

     return (
          <header className={scroll || isBlogPage ? "header header-transparent header-small header-shadow" : "header header-transparent"}>
               <div className="container-fluid">
                    <div className="inner-header">
                         <a className="inner-brand" href="/">
                              {scroll || isBlogPage ? (
                                   <>
                                        <img src={LogoBlack} alt='P2P Logo' />
                                   </>
                              ) : (
                                   <>
                                        {isTabletScreen ? (
                                             <>
                                                  <img src={LogoBlack} alt='P2P Logo' />
                                             </>
                                        ) : (
                                             <>
                                                  <img src={LogoWhite} alt='P2P Logo' />
                                             </>
                                        )}
                                   </>
                              )}
                         </a>
                    </div>
                    <div className="inner-navigation collapse" style={{}}>
                         <div className="inner-nav">
                              <ul>
                                   <li><a href="/#overview"><span className="menu-item-span">Overview</span></a></li>
                                   <li><a href="/#understand-work"><span className="menu-item-span">Understand Work</span></a></li>
                                   <li><a href="/#execute-work"><span className="menu-item-span">Execute Work</span></a></li>
                                   <li><a href="/#improve-work"><span className="menu-item-span">Improve Work</span></a></li>
                                   {/* <li><a href onClick={() => handleScrollToSection(section5Ref)}><span className="menu-item-span">Value Proposition</span></a></li> */}
                                   <li><a href="/#about-us"><span className="menu-item-span">About Us</span></a></li>
                                   <li><a href="/blogs"><span className="menu-item-span">Blogs</span></a></li>
                              </ul>
                         </div>
                    </div>
                    <div className="extra-nav">
                         <ul>
                              <li>
                                   <a href="#contact-us" type="button" data-bs-toggle="offcanvas" data-bs-target="#contact-us" aria-controls="contact-us">
                                        <span className="menu-item-span">
                                             Contact
                                        </span>
                                   </a>
                              </li>
                              <li className="nav-toggle">
                                   <a href=".inner-navigation" data-bs-toggle="collapse" data-bs-target=".inner-navigation" className="collapsed" aria-expanded="false">
                                        <span className="menu-item-span d-flex align-items-center">
                                             Menu
                                             <i className="bi bi-list fs-4 ml-1"></i>
                                        </span>
                                   </a>
                              </li>
                         </ul>
                    </div>
               </div>
          </header>
     );
}

export default Header;